import styled from 'styled-components';
import { Button } from 'antd';

import theme from 'styles/theme';

export const WhatsAppButton = styled(Button)`
  &&& {
    background-color: #25d366;
    color: ${theme.colors.white};

    &:hover {
      background-color: #128c7e !important;
    }
  }
`;
